<template>
  <div class="w-full h-screen bg-[#2B2633] overflow-y-scroll">
    <div class="flex items-center justify-between px-3 py-4 bg-[#202126]">
      <div class="flex items-center gap-3">
        <div class="w-[2px] h-5 line" />
        <p class="text-lg">最新消息</p>
      </div>
      <router-link to="/message"> <van-icon name="cross" size="24px" color="#909093" /></router-link>
    </div>
    <div class="p-4 border-b border-white border-opacity-10">
      <p class="text-2xl">{{ info.title }}</p>
      <p class="text-sm text-[#909093] mt-3">{{ info.create_date }}</p>
    </div>
    <div class="px-4 py-6" v-html="info.content"></div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { get_note_info } from '@/api/message'
const route = useRoute()
const info = ref({})
onMounted(async () => {
  console.log(route.params.id)
  const res = await get_note_info({
    note_id: route.params.id,
  })
  if (!res.error) {
    info.value = res
  }
})
</script>


<style lang="scss" scoped>
.line {
  background: linear-gradient(180deg, #FF2A6D, #FF2AAF);
}
</style>

